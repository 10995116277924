import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const cejour = new Date()
import { stringFromDate, dateFromString, lundi, variables } from './plugins/variables'
import { IUtilisateur } from './plugins/type'
const jour1 = stringFromDate(new Date(cejour.getFullYear(), cejour.getMonth(), 1))
const jour2 = stringFromDate(new Date(cejour.getFullYear(), cejour.getMonth() + 1, 0))
const debSemaine = lundi(stringFromDate(new Date()))
const dte = dateFromString(debSemaine)
const finSemaine = stringFromDate(new Date(dte.getFullYear(), dte.getMonth(), dte.getDate() + 7))

interface IMachine {
  ch_id: number
  mac_id: number
  mac_desi: string
  mac_code: string
  mac_obs: string
  mac_caces: string
  mac_dateentree: string | null
  mac_datesortie: string | null
  mac_num_epack?: number
}


let utilisateur = {
  user: 'non authentifié',
  ut_id: 0,
  administrateur: false
}

let autorisations = []
let s = localStorage.getItem('SPS_IntranetUSER')
if (s != null && s > '') {
  utilisateur = JSON.parse(s)
  if (utilisateur.ut_id == 1 || utilisateur.ut_id == 2) {
    utilisateur.administrateur = true
  } else {
    utilisateur.administrateur = false
  }
}
s = localStorage.getItem('SPS_IntranetACL')
if (s !== null && s !== 'undefined') {
  if (s == '*') {
    utilisateur.administrateur = true
  }
  autorisations = JSON.parse(s)
}

export default new Vuex.Store({
  state: {
    debug: process.env.NODE_ENV === 'development',
    ajaxurl: process.env.NODE_ENV === 'development' ? 'http://localhost/sps-hg/intranet/php/' : '/php/',
    contentWidth: 0,
    contentHeight: 0,
    utilisateur: utilisateur,
    autorisations: autorisations,
    jour1: jour1,
    jour2: jour2,
    debSemaine: debSemaine,
    finSemaine: finSemaine,
    ch_id: 1,
    salaries: [] as IUtilisateur[],
    machines: [],
    tournee: 'MATIN',
    acls: []
  },
  mutations: {
    majContentHeight(state, payload) {
      state.contentWidth = payload.width
      state.contentHeight = payload.height
    },
    majAutorisation(state, autorisations) {
      state.autorisations = autorisations
      variables.autorisation = autorisations
      localStorage.setItem('SPS_IntranetACL', JSON.stringify(autorisations))
    },
    majUtilisateur(state, utilisateur) {
      if (utilisateur.ut_id == 1 || utilisateur.ut_id == 2 || state.autorisations == ['*']) {
        utilisateur.administrateur = true
      } else {
        utilisateur.administrateur = false
      }
      state.utilisateur = utilisateur
      localStorage.setItem('SPS_IntranetUSER', JSON.stringify(utilisateur))
    },
    majMachines(state, machines) {
      state.machines = machines
    },
    majSalaries(state, salaries: IUtilisateur[]) {
      state.salaries = salaries
    },
    majPeriode(state, payload) {
      state.jour1 = payload.jour1
      state.jour2 = payload.jour2
    },
    majPeriodePlanning(state, payload) {
      state.debSemaine = payload.debSemaine
      state.finSemaine = payload.finSemaine
    },
    majTournee(state, tournee) {
      state.tournee = tournee
    }
  },
  getters: {
    listeMachines: (state) => {
      // retourne un tableau de machines
      return (chantierID: number, jour1: string, jour2: string): IMachine[] => {
        const machines: IMachine[] = []
        const strjour = jour1.substr(0, 10)
        const strjour2 = jour2.substr(0, 10)

        for (let n = 0; n < state.machines.length; n++) {
          const machine: IMachine = state.machines[n]
          if (machine.ch_id !== chantierID) continue
          if (machine.mac_datesortie && machine.mac_datesortie < strjour) continue
          if (machine.mac_dateentree && machine.mac_dateentree > strjour2) continue
          machines.push(machine)
        }
        return machines
      }
    },
    listeSalaries: (state) => {
      return (chantierID: number, jour1: string, jour2: string): IUtilisateur[] => {
        const salaries: IUtilisateur[] = []
        const strjour = jour1.substr(0, 10)
        const strjour2 = jour2.substr(0, 10)

        for (let n = 0; n < state.salaries.length; n++) {
          const salarie: IUtilisateur = state.salaries[n]
          if (salarie.ch_id !== chantierID) continue
          if (salarie.ut_date_sortie && salarie.ut_date_sortie < strjour) continue
          if (salarie.ut_date_entree && salarie.ut_date_entree > strjour2) continue
          salaries.push(salarie)
        }
        return salaries
      }
    },
    tousLesSalaries: (state) => {
      return (): IUtilisateur[] => {
        const salaries: IUtilisateur[] = []
        const strjour = jour1.substring(0, 9)
        const strjour2 = jour2.substring(0, 9)

        for (let n = 0; n < state.salaries.length; n++) {
          const salarie: IUtilisateur = state.salaries[n]
          if (salarie.ut_date_sortie && salarie.ut_date_sortie < strjour) continue
          if (salarie.ut_date_entree && salarie.ut_date_entree > strjour2) continue
          const ut_nomPrenom: string = salarie.ut_prenom + ' ' + salarie.ut_nom
          if (ut_nomPrenom.split(' ')[0] != salarie.ut_nom.split(' ')[0]) {
            salarie.ut_nom = ut_nomPrenom
          }
          salaries.push(salarie)
        }
        return salaries
      }
    }
  }
})
