import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}]},[_vm._v(_vm._s(_vm.message))]),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),(_vm.options.mode == 'confirm')?[_c(VBtn,{attrs:{"depressed":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Non")]),_c(VBtn,{attrs:{"color":_vm.options.color,"dark":"","depressed":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v("Oui")])]:[_c(VBtn,{attrs:{"depressed":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Fermer")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }