import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{staticClass:"grey lighten-4"},[_c(VNavigationDrawer,{attrs:{"mini-variant":_vm.mini,"stateless":"","dark":"","app":"","width":"256"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VList,{attrs:{"dense":""}},[_c(VListGroup,{attrs:{"no-action":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Gestion avancée")])],1)]},proxy:true}])},[_c(VList,_vm._l((_vm.optionGestion),function(option){return _c(VListItem,{key:option.title,attrs:{"link":""},on:{"click":function($event){return _vm.ouvrirPage(option.path)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":option.icon_color}},[_vm._v(_vm._s(option.icon))]),_vm._v(" "+_vm._s(option.title)+" ")],1)],1)}),1)],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-power")])],1),_c(VListItemContent,[_c(VListItemTitle,[(_vm.utilisateur.user == '')?[_vm._v("Déconnexion")]:[_vm._v(_vm._s(_vm.utilisateur.user))]],2)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.authentifie),callback:function ($$v) {_vm.authentifie=$$v},expression:"authentifie"}},[(_vm.authentifie)?_c(VList,_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.path}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":item.icon_color}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1):_vm._e()],1),_c(VMain,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('router-view',{on:{"reduire":_vm.reduire,"toggleDrawer":_vm.toggleDrawer,"drawerOff":_vm.drawerOff}})],1),_c('confirm',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }