
import Vue from 'vue'
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})

 * TypeScript
 * interface IConfirmer extends Vue {
 *   openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
 * }
 */

export default Vue.extend({
  name: 'Confirm',
  data: () => ({
    dialog: false,
    resolve: null as any,
    reject: null as any,
    message: null as null | string,
    title: null as null | string,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200,
      mode: 'confirm' // 'info'
    }
  }),
  methods: {
    openDialogConfirm(title: string, message: string, options: object): Promise<boolean> {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise<boolean>((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree(): void {
      this.resolve(true)
      this.dialog = false
    },
    cancel(): void {
      this.resolve(false)
      this.dialog = false
    }
  }
})
